import { ShoppingListItemModel } from '../model/shopping-list-item.model';
import { WithId } from '../model/with-id.model';
import ListGroup from 'react-bootstrap/ListGroup';
import React, { useCallback, useState } from 'react';
import ShoppingListItemEditModal from './ShoppingListItemEditModal';
import { ShoppingListItemDetailsModel } from '../model/shopping-list-item-details.model';
import { useShoppingListFunctions } from './ShoppingListProvider';
import { DraggableProvided } from '@react-forked/dnd';

type Props = {
  item: WithId<ShoppingListItemModel>;
  draggableProvided?: DraggableProvided;
};

const ShoppingListItem = React.memo(function ShoppingListItem({
  item: { id, name, note, quantity, markedAsDone },
  draggableProvided
}: Props) {
  const { updateItem, updateDoneStatus, deleteItem } = useShoppingListFunctions();
  const [showEditModal, setShowEditModal] = useState(false);

  const openEditModal = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const onSaveDetails = useCallback(
    async (itemDetails: ShoppingListItemDetailsModel) => {
      await updateItem(itemDetails, id, markedAsDone);
    },
    [id, markedAsDone, updateItem]
  );

  const onToggleDone = useCallback(async () => {
    await updateDoneStatus(id, markedAsDone);
  }, [id, markedAsDone, updateDoneStatus]);

  const onDelete = useCallback(async () => {
    await deleteItem(id);
  }, [deleteItem, id]);

  return (
    <>
      <ListGroup.Item variant={markedAsDone ? 'dark' : ''} onClick={openEditModal}>
        {draggableProvided?.dragHandleProps ? (
          <i {...draggableProvided.dragHandleProps} className="bi bi-grip-horizontal py-2 ps-0 pe-3" />
        ) : null}
        {name}
        {quantity ? ` (${quantity})` : ''}
        {note ? <small className="text-muted ms-1">{note}</small> : null}
        <div className="d-inline float-end">
          <i
            className={`bi-${markedAsDone ? 'x-circle' : 'check-circle'} cursor-pointer`}
            role="img"
            aria-label={markedAsDone ? 'Set open' : 'Set done'}
            onClick={onToggleDone}
          />
          {markedAsDone ? (
            <i className="bi-trash ms-2 cursor-pointer" role="img" aria-label="Delete item" onClick={onDelete} />
          ) : null}
        </div>
      </ListGroup.Item>
      <ShoppingListItemEditModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        name={name}
        note={note}
        quantity={quantity}
        onSaveDetails={onSaveDetails}
      />
    </>
  );
});

export default ShoppingListItem;
