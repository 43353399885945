import usePrevious from './usePrevious';
import { useEffect, useState } from 'react';
import { doc as docRef, onSnapshot } from 'firebase/firestore';
import { WithId } from '../model/with-id.model';
import { useFirebase } from '../components/FirebaseProvider';
import { useErrorHandler } from 'react-error-boundary';

function useDoc<T>(docPath: string | null) {
  const { firestore } = useFirebase();
  const prevDocPath = usePrevious(docPath);

  const [doc, setDoc] = useState<WithId<T> | null>(null);
  const [unsubscribeDoc, setUnsubscribeDoc] = useState<(() => void) | null>(null);

  const handleError = useErrorHandler();

  useEffect(() => {
    if (docPath && (prevDocPath !== docPath || !unsubscribeDoc)) {
      if (unsubscribeDoc) {
        unsubscribeDoc();
      }

      const listUnsubscribe = onSnapshot(
        docRef(firestore, docPath),
        snapshot => {
          if (snapshot.exists()) {
            const snapshotData = snapshot.data() as T;
            const doc = { ...snapshotData, id: snapshot.id };
            setDoc(doc);
          } else {
            handleError(new Error(`The doc '${docPath}' you asked for does not exist.`));
          }
        },
        handleError
      );

      setUnsubscribeDoc(() => () => {
        listUnsubscribe();
      });
    } else if (!docPath) {
      setDoc(null);
      if (unsubscribeDoc) {
        unsubscribeDoc();
      }
    }

    return () => {
      if (unsubscribeDoc) {
        unsubscribeDoc();
        setUnsubscribeDoc(null);
      }
    };
  }, [docPath, firestore, handleError, prevDocPath, unsubscribeDoc]);

  return doc;
}

export default useDoc;
