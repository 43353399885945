import { DragDropContext, Droppable, DropResult } from '@react-forked/dnd';
import DraggableList from './DraggableList';
import React, { useCallback } from 'react';
import { useShoppingListFunctions } from './ShoppingListProvider';
import { WithId } from '../model/with-id.model';
import { ShoppingListItemModel } from '../model/shopping-list-item.model';

type Props = {
  itemsOpen: WithId<ShoppingListItemModel>[];
};

function OpenItemsList({ itemsOpen }: Props) {
  const { updateIndex } = useShoppingListFunctions();

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.source.index === result.destination.index) {
        return;
      }

      await updateIndex(result.source.index, result.destination.index);
    },
    [updateIndex]
  );

  return itemsOpen.length > 0 ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <DraggableList items={itemsOpen} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <div className="text-center my-4">keine Artikel vorhanden 🤷‍</div>
  );
}

export default OpenItemsList;
