import { useCallback } from 'react';
import { useCurrentUser } from '../components/AuthProvider';
import useUpdateDoc from './useUpdateDoc';

function useSetMainList() {
  const { user } = useCurrentUser();
  const userId = user?.uid;

  const updateDoc = useUpdateDoc(`users/${userId}`);

  return useCallback(
    (listId: string) => {
      if (!userId) {
        return Promise.reject();
      }

      return updateDoc({ mainListId: listId });
    },
    [updateDoc, userId]
  );
}

export default useSetMainList;
