import { WithId } from '../model/with-id.model';
import { ShoppingListItemModel } from '../model/shopping-list-item.model';
import { useShoppingListFunctions } from './ShoppingListProvider';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ShoppingListItem from './ShoppingListItem';

type Props = {
  itemsDone: WithId<ShoppingListItemModel>[];
};

function DoneItemsList({ itemsDone }: Props) {
  const { deleteAllItems, markAllItemsOpen } = useShoppingListFunctions();
  const [doneExpanded, setDoneExpanded] = useState(false);

  const containsItemsDone = itemsDone.length > 0;

  useEffect(() => {
    if (!containsItemsDone) {
      setDoneExpanded(false);
    }
  }, [containsItemsDone]);

  const onToggleDoneExpand = useCallback(() => {
    if (doneExpanded) {
      setDoneExpanded(false);
    } else if (containsItemsDone) {
      setDoneExpanded(true);
    }
  }, [doneExpanded, containsItemsDone]);

  return (
    <>
      <h5 className={`mt-4 ${containsItemsDone ? 'cursor-pointer' : ''}`} onClick={onToggleDoneExpand}>
        <i className={`bi bi-caret-${doneExpanded ? 'down' : 'right'}-fill me-2`} />
        erledigt ({itemsDone.length})
        <div className="d-inline float-end">
          <Button size="sm" variant="outline-dark" onClick={markAllItemsOpen} disabled={!containsItemsDone}>
            <i className="bi-x-circle" />
          </Button>
          <Button size="sm" variant="outline-dark" onClick={deleteAllItems} disabled={!containsItemsDone}>
            <i className="bi-trash" />
          </Button>
        </div>
      </h5>
      {doneExpanded ? (
        <ListGroup variant="flush">
          {itemsDone.map(item => (
            <ShoppingListItem key={item.id} item={item} />
          ))}
        </ListGroup>
      ) : null}
    </>
  );
}

export default DoneItemsList;
