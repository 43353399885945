import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import usePrevious from '../hooks/usePrevious';
import { useFirebase } from './FirebaseProvider';
import { onAuthStateChanged, User } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import LoadingSpinner from './LoadingSpinner';
import { useErrorHandler } from 'react-error-boundary';

export type SimplifiedUser = Pick<User, 'uid' | 'email' | 'emailVerified'>;

export type ShoppingListUserModel = {
  user: SimplifiedUser | null;
  mainListId: string | null;
};

export type UserProfile = { mainListId?: string };

const AuthContext = createContext<ShoppingListUserModel | undefined>(undefined);
AuthContext.displayName = 'CurrentUserContext';

function AuthProvider({ children }: PropsWithChildren<any>) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<SimplifiedUser | null>(null);
  const [currentMainListId, setCurrentMainListId] = useState<string | null>(null);
  const [unsubscribeUser, setUnsubscribeUser] = useState<(() => void) | null>(null);
  const userId = currentUser?.uid || null;
  const prevUserId = usePrevious<string | null>(userId);
  const { auth, firestore } = useFirebase();
  const handleError = useErrorHandler();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(
      auth,
      user => {
        if (user) {
          const { uid, email, emailVerified } = user;
          setCurrentUser({
            uid,
            email,
            emailVerified
          });
        } else {
          setCurrentUser(null);
        }
        setCurrentMainListId(null);
        setLoading(false);
      },
      handleError
    );

    return () => {
      unsubscribeAuth();
    };
  }, [auth, handleError]);

  useEffect(() => {
    if (userId && (prevUserId !== userId || !unsubscribeUser)) {
      if (unsubscribeUser) {
        unsubscribeUser();
      }

      const userUnsubscribe = onSnapshot(
        doc(firestore, `users/${userId}`),
        userSnapshot => {
          if (userSnapshot.exists()) {
            const userProfile = userSnapshot.data() as UserProfile;
            setCurrentMainListId(userProfile.mainListId ?? null);
          }
        },
        handleError
      );

      setUnsubscribeUser(() => () => {
        userUnsubscribe();
      });
    } else if (!userId) {
      setCurrentMainListId(null);
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    }

    return () => {
      if (unsubscribeUser) {
        unsubscribeUser();
        setUnsubscribeUser(null);
      }
    };
  }, [prevUserId, userId, unsubscribeUser, firestore, handleError, currentUser]);

  const value = useMemo(() => ({ user: currentUser, mainListId: currentMainListId }), [currentUser, currentMainListId]);

  return <AuthContext.Provider value={value}>{loading ? <LoadingSpinner /> : children}</AuthContext.Provider>;
}

export function useCurrentUser() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useCurrentUser must be used within an AuthProvider`);
  }
  return context;
}

export default AuthProvider;
