import usePrevious from './usePrevious';
import { useEffect, useState } from 'react';
import { collection, FieldPath, onSnapshot, query, where, WhereFilterOp } from 'firebase/firestore';
import { WithId } from '../model/with-id.model';
import { useFirebase } from '../components/FirebaseProvider';
import { useErrorHandler } from 'react-error-boundary';

export interface CollectionFilter {
  field: string | FieldPath;
  operator: WhereFilterOp;
  value: any;
}

function useCollectionWithFilter<T>(collectionPath: string | null, { field, operator, value }: CollectionFilter) {
  const { firestore } = useFirebase();
  const prevCollectionPath = usePrevious(collectionPath);
  const prevField = usePrevious(field);
  const prevOperator = usePrevious(operator);
  const prevValue = usePrevious(value);

  const [docs, setDocs] = useState<WithId<T>[]>([]);
  const [unsubscribeCollection, setUnsubscribeCollection] = useState<(() => void) | null>(null);

  const handleError = useErrorHandler();

  useEffect(() => {
    if (
      collectionPath &&
      (collectionPath !== prevCollectionPath ||
        field !== prevField ||
        operator !== prevOperator ||
        value !== prevValue ||
        !unsubscribeCollection)
    ) {
      if (unsubscribeCollection) {
        unsubscribeCollection();
      }

      const collectionUnsubscribe = onSnapshot(
        query(collection(firestore, collectionPath), where(field, operator, value)),
        querySnapshot => {
          const docs = querySnapshot.docs
            .filter(snapShot => snapShot.exists)
            .map(snapshot => ({ ...(snapshot.data() as T), id: snapshot.id }));
          setDocs(docs);
        },
        handleError
      );

      setUnsubscribeCollection(() => () => {
        collectionUnsubscribe();
      });
    } else if (!collectionPath) {
      setDocs([]);
      if (unsubscribeCollection) {
        unsubscribeCollection();
      }
    }

    return () => {
      if (unsubscribeCollection) {
        unsubscribeCollection();
        setUnsubscribeCollection(null);
      }
    };
  }, [
    collectionPath,
    field,
    firestore,
    handleError,
    operator,
    prevCollectionPath,
    prevField,
    prevOperator,
    prevValue,
    unsubscribeCollection,
    value
  ]);

  return docs;
}

export default useCollectionWithFilter;
