import styles from './ItemInput.module.scss';
import usePrevious from '../hooks/usePrevious';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useCombobox } from 'downshift';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useFirebase } from './FirebaseProvider';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { useShoppingListFunctions } from './ShoppingListProvider';
import { useCurrentUser } from './AuthProvider';

type ItemsState = {
  loading: boolean;
  error: any | null;
  items: string[];
};

type ItemsAction =
  | { type: 'RESET' }
  | { type: 'LOADING' }
  | { type: 'FETCH_ERROR'; error: any }
  | { type: 'ITEMS_FETCHED'; items: string[] };

function itemsReducer(state: ItemsState, action: ItemsAction) {
  switch (action.type) {
    case 'RESET': {
      return {
        loading: false,
        error: null,
        items: []
      };
    }
    case 'LOADING': {
      return {
        loading: true,
        error: null,
        items: []
      };
    }
    case 'ITEMS_FETCHED': {
      return {
        loading: false,
        error: null,
        items: action.items
      };
    }
    case 'FETCH_ERROR': {
      return {
        loading: false,
        error: action.error,
        items: []
      };
    }
  }
}

type Props = {
  listId: string;
};

function ItemInput({ listId }: Props) {
  const { user } = useCurrentUser();
  const { firestore } = useFirebase();
  const { createItem } = useShoppingListFunctions();
  const prevListId = usePrevious(listId);
  const [{ loading, error, items }, dispatchItems] = useReducer(itemsReducer, {
    loading: false,
    error: null,
    items: []
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = useCallback(
    async (inputValue: string, closeMenu: () => void) => {
      const sanitizedInputValue = inputValue ? inputValue.trim().toLowerCase() : inputValue;
      if (sanitizedInputValue) {
        dispatchItems({ type: 'LOADING' });

        try {
          const querySnapshot = await getDocs(
            query(
              collection(firestore, 'items'),
              where('searchIndex', 'array-contains', sanitizedInputValue),
              where(`users.${user?.uid}`, '==', true),
              limit(10)
            )
          );
          const autocompleteItems = querySnapshot.docs.map(snapshot => snapshot.data().name as string);
          dispatchItems({ type: 'ITEMS_FETCHED', items: autocompleteItems });
          if (autocompleteItems.length < 1) {
            closeMenu();
          }
        } catch (error) {
          dispatchItems({ type: 'FETCH_ERROR', error });
        }
      } else {
        dispatchItems({ type: 'RESET' });
        closeMenu();
      }
    },
    [firestore, user?.uid]
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onCreateItem = useCallback(
    async (inputValue: string | null | undefined, reset: () => void) => {
      await createItem(inputValue, reset);
      inputRef.current?.focus();
    },
    [createItem]
  );

  const {
    inputValue,
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset,
    closeMenu,
    setInputValue
  } = useCombobox({
    items,
    onInputValueChange: async ({ inputValue }) => {
      await debouncedChangeHandler(inputValue || '', closeMenu);
    },
    onSelectedItemChange: async ({ selectedItem }) => {
      await onCreateItem(selectedItem, reset);
    }
  });

  useEffect(() => {
    if (prevListId !== listId) {
      reset();
    }
  }, [listId, prevListId, reset]);

  return (
    <div className="sticky-top">
      <div className={`${styles.inputContainer} bg-white`}>
        <InputGroup>
          <Button
            variant="outline-secondary"
            id="input-button"
            disabled={!inputValue || !inputValue.trim()}
            onClick={async () => {
              await onCreateItem(inputValue, reset);
            }}
          >
            <i className="bi-plus-circle" role="img" aria-label="Add item" />
          </Button>
          <FormControl
            ref={inputRef}
            aria-label="Item input field"
            aria-describedby="input-button"
            {...getInputProps({
              onKeyDown: async event => {
                if (event.key === 'Tab' && highlightedIndex >= 0) {
                  // @ts-ignore
                  event.nativeEvent.preventDownshiftDefault = true;
                  event.preventDefault();
                  setInputValue(items[highlightedIndex]);
                } else if (event.key === 'Enter' && highlightedIndex < 0) {
                  // @ts-ignore
                  event.nativeEvent.preventDownshiftDefault = true;
                  await onCreateItem(inputValue, reset);
                }
              }
            })}
            autoFocus
          />
        </InputGroup>
      </div>
      <ul {...getMenuProps()} className={`list-group ${styles.downshiftMenu} ${!isOpen ? 'visually-hidden' : ''}`}>
        {isOpen ? (
          loading ? (
            <li className="list-group-item list-group-item-dark">loading...</li>
          ) : error ? (
            <li className="list-group-item list-group-item-dark">error: {error}</li>
          ) : (
            items.map((item, index) => (
              <li
                {...getItemProps({
                  key: item,
                  index,
                  item
                })}
                className={`list-group-item list-group-item-${highlightedIndex === index ? 'primary' : 'secondary'}`}
              >
                {item}
              </li>
            ))
          )
        ) : null}
      </ul>
    </div>
  );
}

export default ItemInput;
