type ItemInputData = {
  name: string;
  note?: string;
  quantity?: number;
};

const itemPattern = /^\s*(((\w|\d|[äöüÄÖÜß])+\s+)+)(\[((\w|\d|[äöüÄÖÜß]|\s)+)\]\s*)?(\((\d+)\)\s*)?(\d+)?\s*$/;

export const parseItemData = (input: string): ItemInputData => {
  if (!input) {
    return { name: input };
  }

  const match = itemPattern.exec(input);
  if (!match) {
    return { name: input };
  }

  const name = match[1].trim();
  const data: ItemInputData = { name: name };

  const note = match[5];
  if (note) {
    data.note = note.trim();
  }

  if (match[8]) {
    data.quantity = Number(match[8]);
  } else if (match[9]) {
    data.quantity = Number(match[9]);
  }

  return data;
};
