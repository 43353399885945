import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useFirebase } from './FirebaseProvider';
import { useQuery } from '@tanstack/react-query';
import { httpsCallableFromURL } from 'firebase/functions';
import { UserInfo } from 'firebase/auth';

type Users = {
  users: UserInfo[];
};

const UsersContext = createContext<Users | undefined>(undefined);
UsersContext.displayName = 'UsersContext';

function UsersProvider({ children }: PropsWithChildren<any>) {
  const { functions } = useFirebase();
  const callable = useMemo(
    () => httpsCallableFromURL<void, UserInfo[]>(functions, 'https://userfetchcall-lno53e5lga-uc.a.run.app'),
    [functions]
  );

  const { data = [] } = useQuery<UserInfo[]>({
    queryKey: ['users'],
    queryFn: async () => {
      const callableResult = await callable();
      return callableResult.data;
    },
    refetchInterval: 1000 * 60 * 5
  });

  const value = useMemo(() => ({ users: data }), [data]);

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}

export function useUsers() {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error(`useUsers must be used within an UsersProvider`);
  }
  return context.users;
}

export default UsersProvider;
