import { FieldPath, WhereFilterOp } from 'firebase/firestore';
import useCollectionWithFilter from './useCollectionWithFilter';
import { ShoppingListModel } from '../model/shopping-list.model';

export interface Filter {
  field: string | FieldPath;
  operator: WhereFilterOp;
  value: any;
}

function useListsWithFilter(filter: Filter) {
  return useCollectionWithFilter<ShoppingListModel>('lists', filter);
}

export default useListsWithFilter;
