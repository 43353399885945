import React from 'react';
import ItemInput from './ItemInput';
import OpenItemsList from './OpenItemsList';
import { useOutletContext } from 'react-router-dom';
import { ListOutletContext } from './ShoppingListContainer';
import DoneItemsList from './DoneItemsList';

function ShoppingList() {
  const {
    list: { id },
    itemsOpen,
    itemsDone
  } = useOutletContext<ListOutletContext>();

  return (
    <>
      <ItemInput listId={id} />
      <OpenItemsList itemsOpen={itemsOpen} />
      <DoneItemsList itemsDone={itemsDone} />
    </>
  );
}

export default ShoppingList;
