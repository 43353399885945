import { useSetTitle } from './TitleProvider';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { ShoppingListModel } from '../model/shopping-list.model';
import { useCurrentUser } from './AuthProvider';
import { useFirebase } from './FirebaseProvider';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import useSetMainList from '../hooks/useSetMainList';

function CreateShoppingList() {
  useSetTitle('Neue Liste erstellen');
  const { user } = useCurrentUser();
  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const updateMainList = useSetMainList();

  const [listName, setListName] = useState('');
  const [mainList, setMainList] = useState(false);

  const onNameInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setListName(event.target.value ?? '');
  }, []);

  const onToggleMainList = useCallback(() => {
    setMainList(prevState => !prevState);
  }, []);

  const trimmedListName = listName.trim();

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (trimmedListName && user) {
        const newList: ShoppingListModel = {
          name: trimmedListName,
          createdAt: new Date().getDate(),
          numberOfItems: 0,
          userId: user.uid,
          sharedUserIds: []
        };

        const listDoc = await addDoc(collection(firestore, 'lists'), newList);

        if (mainList) {
          await updateMainList(listDoc.id);
        }

        navigate(`../${listDoc.id}`);
      }
    },
    [firestore, mainList, navigate, trimmedListName, updateMainList, user]
  );

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>Listen-Name</Form.Label>
        <Form.Control type="text" placeholder="Name" value={listName} onChange={onNameInputChange} required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="mainList">
        <Form.Check
          type="checkbox"
          label="Als Hauptliste festlegen"
          defaultChecked={mainList}
          onChange={onToggleMainList}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={!trimmedListName}>
        Liste erstellen
      </Button>
    </Form>
  );
}

export default CreateShoppingList;
