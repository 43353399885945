import { useCallback } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { useFirebase } from '../components/FirebaseProvider';
import { useErrorHandler } from 'react-error-boundary';

function useDeleteDoc(docPath: string) {
  const { firestore } = useFirebase();
  const handleError = useErrorHandler();

  return useCallback(() => deleteDoc(doc(firestore, docPath)).catch(handleError), [docPath, firestore, handleError]);
}

export default useDeleteDoc;
