import { useCallback } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirebase } from '../components/FirebaseProvider';
import { useErrorHandler } from 'react-error-boundary';

function useUpdateDoc<T = any>(docPath: string) {
  const { firestore } = useFirebase();
  const handleError = useErrorHandler();

  return useCallback(
    (docUpdate: Partial<T>) => {
      return updateDoc(doc(firestore, docPath), docUpdate as any).catch(handleError);
    },
    [firestore, docPath, handleError]
  );
}

export default useUpdateDoc;
