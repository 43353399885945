import { useCurrentUser } from '../components/AuthProvider';
import useListsWithFilter from './useListsWithFilter';
import { useMemo } from 'react';

function useUserLists() {
  const { user: currentUser, mainListId } = useCurrentUser();
  const userId = currentUser?.uid || null;

  const userLists = useListsWithFilter({ field: 'userId', operator: '==', value: userId });
  const sharedLists = useListsWithFilter({
    field: 'sharedUserIds',
    operator: 'array-contains',
    value: userId
  });

  return useMemo(() => {
    if (mainListId) {
      const mainList = userLists.concat(sharedLists).find(list => list.id === mainListId);
      const filteredUserLists = userLists.filter(list => list.id !== mainListId);
      const filteredSharedLists = sharedLists.filter(list => list.id !== mainListId);
      return { mainList, userLists: filteredUserLists, sharedLists: filteredSharedLists };
    } else {
      return { mainList: undefined, userLists, sharedLists };
    }
  }, [mainListId, sharedLists, userLists]);
}

export default useUserLists;
