import styles from './Layout.module.scss';
import Sidebar from './Sidebar';
import TitleProvider from './TitleProvider';
import Content from './Content';
import { useCallback, useEffect, useState } from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '../hooks/useMediaQuery';

function Layout() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const matchesXL = useMediaQuery('xl');

  useEffect(() => {
    if (matchesXL) {
      setSidebarVisible(true);
    }
  }, [matchesXL]);

  const hideSidebar = useCallback(() => {
    if (!matchesXL) {
      setSidebarVisible(false);
    }
  }, [matchesXL]);
  const toggleSidebar = useCallback(() => setSidebarVisible(visible => !visible), []);

  return (
    <div className={styles.rootContainer}>
      <Sidebar visible={sidebarVisible} hideSidebar={hideSidebar} />
      <div className="d-flex flex-column w-100">
        <TitleProvider>
          <Header toggleSidebar={toggleSidebar} />
          <Content>
            <Outlet />
          </Content>
        </TitleProvider>
      </div>
    </div>
  );
}

export default Layout;
