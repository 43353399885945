import { WithId } from '../model/with-id.model';
import { ShoppingListItemModel } from '../model/shopping-list-item.model';
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Draggable } from '@react-forked/dnd';
import ShoppingListItem from './ShoppingListItem';

type Props = {
  items: WithId<ShoppingListItemModel>[];
};

const DraggableList = React.memo(function DraggableList({ items }: Props) {
  return (
    <ListGroup variant="flush">
      {items.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <ShoppingListItem item={item} draggableProvided={provided} />
            </div>
          )}
        </Draggable>
      ))}
    </ListGroup>
  );
});

export default DraggableList;
