import { ShoppingListItemModel } from '../model/shopping-list-item.model';
import useCollectionWithFilter, { CollectionFilter } from './useCollectionWithFilter';

function useListItems(listId: string | null, markedAsDone: boolean) {
  const filter: CollectionFilter = {
    field: 'markedAsDone',
    operator: '==',
    value: markedAsDone
  };

  const items = useCollectionWithFilter<ShoppingListItemModel>(listId ? `lists/${listId}/items` : null, filter);

  return items.sort((a, b) => a.index - b.index);
}

export default useListItems;
