import { useTitle } from './TitleProvider';
import Button from 'react-bootstrap/Button';

type Props = { toggleSidebar: () => void };

function Header({ toggleSidebar }: Props) {
  const title = useTitle();

  return (
    <header className="d-flex flex-wrap justify-content-center text-white bg-dark w-100">
      <Button onClick={toggleSidebar} variant="outline-light" aria-label="toggle sidebar" className="border-0">
        <i className="bi bi-list" />
      </Button>
      &nbsp;
      <a href="/" className="d-flex align-items-center mx-auto text-white text-decoration-none">
        <span aria-label="title" className="fs-4">
          {title}
        </span>
      </a>
    </header>
  );
}

export default Header;
