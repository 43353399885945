import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ShoppingListItemDetailsModel } from '../model/shopping-list-item-details.model';

type Props = {
  showEditModal: boolean;
  setShowEditModal: (value: boolean) => void;
  onSaveDetails: (itemDetails: ShoppingListItemDetailsModel) => void;
} & ShoppingListItemDetailsModel;

function ShoppingListItemEditModal({ showEditModal, setShowEditModal, name, note, quantity, onSaveDetails }: Props) {
  const [nameInputValue, setNameInputValue] = useState(name);
  const initialNoteValue = useMemo(() => note || '', [note]);
  const [noteInputValue, setNoteInputValue] = useState(initialNoteValue);
  const initialQuantityValue = useMemo(() => (quantity ? `${quantity}` : ''), [quantity]);
  const [quantityInputValue, setQuantityInputValue] = useState(initialQuantityValue);

  const onNameInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    setNameInputValue(event.target.value);
  }, []);

  const onNoteInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    setNoteInputValue(event.target.value);
  }, []);

  const onQuantityInputChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    setQuantityInputValue(event.target.value);
  }, []);

  const isNameInvalid = useMemo(() => !nameInputValue || !nameInputValue.trim(), [nameInputValue]);

  const isFormInvalid = useMemo(
    () =>
      isNameInvalid ||
      (name === nameInputValue && initialNoteValue === noteInputValue && initialQuantityValue === quantityInputValue),
    [initialNoteValue, initialQuantityValue, isNameInvalid, name, nameInputValue, noteInputValue, quantityInputValue]
  );

  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
    setNameInputValue(name);
    setNoteInputValue(initialNoteValue);
    setQuantityInputValue(initialQuantityValue);
  }, [initialNoteValue, initialQuantityValue, name, setShowEditModal]);

  const saveItemDetails = useCallback(() => {
    const newName = nameInputValue.trim();
    const newNote = noteInputValue?.trim();
    const parsedQuantity = Number.parseInt(quantityInputValue, 10);
    const newQuantity = Number.isNaN(parsedQuantity) ? undefined : parsedQuantity;

    const newItem = { name: newName, note: newNote, quantity: newQuantity };

    onSaveDetails(newItem);

    setNameInputValue(newName);
    setNoteInputValue(newNote);
    setQuantityInputValue(newQuantity ? `${newQuantity}` : '');

    setShowEditModal(false);
  }, [nameInputValue, noteInputValue, onSaveDetails, quantityInputValue, setShowEditModal]);

  return (
    <Modal show={showEditModal} onHide={closeEditModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Artikel Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Name"
                value={nameInputValue}
                onChange={onNameInputChange}
                required
                isInvalid={isNameInvalid}
              />
              <Form.Control.Feedback type="invalid">Wähle bitte einen Namen für den Artikel.</Form.Control.Feedback>
            </Col>
          </FormGroup>
          <FormGroup as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Notiz
            </Form.Label>
            <Col sm={10}>
              <Form.Control type="text" placeholder="Notiz" value={noteInputValue} onChange={onNoteInputChange} />
            </Col>
          </FormGroup>
          <FormGroup as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Anzahl
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                type="number"
                min={1}
                placeholder="Anzahl"
                value={quantityInputValue}
                onChange={onQuantityInputChange}
              />
            </Col>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={closeEditModal}>
          abbrechen
        </Button>
        <Button variant="dark" onClick={saveItemDetails} disabled={isFormInvalid}>
          speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShoppingListItemEditModal;
