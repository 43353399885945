import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

type TitleContextType = {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
};

const TitleContext = createContext<TitleContextType | undefined>(undefined);
TitleContext.displayName = 'TitleContext';

function TitleProvider({ children }: PropsWithChildren<any>) {
  const [title, setTitle] = useState<string>('Shopping List App');

  const value = useMemo(() => ({ title, setTitle }), [title]);

  return <TitleContext.Provider value={value}>{children}</TitleContext.Provider>;
}

export function useTitle() {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error(`useTitle must be used within an TitleProvider`);
  }
  return context.title;
}

export function useSetTitle(newTitle: string) {
  const context = useContext(TitleContext);
  if (context === undefined) {
    throw new Error(`useTitle must be used within an TitleProvider`);
  }

  const { title, setTitle } = context;
  useEffect(() => {
    if (newTitle && title !== newTitle) {
      setTitle(newTitle);
    }
  }, [newTitle, setTitle, title]);
}

export default TitleProvider;
