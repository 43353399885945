import React, { useMemo } from 'react';
import { useSetTitle } from './TitleProvider';
import { useShoppingList } from './ShoppingListProvider';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { WithId } from '../model/with-id.model';
import { ShoppingListModel } from '../model/shopping-list.model';
import { ShoppingListItemModel } from '../model/shopping-list-item.model';

export type ListOutletContext = {
  list: Pick<WithId<ShoppingListModel>, 'id' | 'name' | 'userId' | 'sharedUserIds'>;
  itemsOpen: WithId<ShoppingListItemModel>[];
  itemsDone: WithId<ShoppingListItemModel>[];
};

function ShoppingListContainer() {
  const { list, itemsOpen, itemsDone, isDirty } = useShoppingList();
  useSetTitle(list?.name ?? '');

  const listOutletContext: ListOutletContext = useMemo(
    () => ({
      list: {
        id: list?.id ?? '',
        name: list?.name ?? '',
        userId: list?.userId ?? '',
        sharedUserIds: list?.sharedUserIds ?? []
      },
      itemsOpen,
      itemsDone
    }),
    [itemsDone, itemsOpen, list?.id, list?.name, list?.userId, list?.sharedUserIds]
  );

  return (
    <>
      {list ? <Outlet context={listOutletContext} /> : null}
      {isDirty ? <LoadingSpinner /> : null}
    </>
  );
}

export default ShoppingListContainer;
