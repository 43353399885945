import Spinner from 'react-bootstrap/Spinner';
import React from 'react';

function LoadingSpinner() {
  return (
    <div className="loading-container">
      <div className="loading-spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
}

export default React.memo(LoadingSpinner);
