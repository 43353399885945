import Alert from 'react-bootstrap/Alert';

type Props = {
  error?: Error;
};

function GlobalError({ error }: Props) {
  return (
    <Alert variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>{error?.message}</p>
      <hr />
      <Alert.Link href="/">Try again</Alert.Link>
    </Alert>
  );
}

export default GlobalError;
