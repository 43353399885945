import styles from './Content.module.scss';
import { PropsWithChildren } from 'react';

function Content({ children }: PropsWithChildren<any>) {
  return (
    <div className={styles.contentContainerOuter}>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
}

export default Content;
