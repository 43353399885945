import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer
} from 'react';
import { ShoppingListModel } from '../model/shopping-list.model';
import { WithId } from '../model/with-id.model';
import { ShoppingListItemModel } from '../model/shopping-list-item.model';
import useListItems from '../hooks/useListItems';
import { useParams } from 'react-router-dom';
import useDoc from '../hooks/useDoc';
import usePrevious from '../hooks/usePrevious';
import { Dirtyable } from '../model/dirtyable.model';
import { addDoc, collection, deleteField, doc, Firestore, updateDoc, writeBatch } from 'firebase/firestore';
import { parseItemData } from '../item.utils';
import { useFirebase } from './FirebaseProvider';
import { ShoppingListItemDetailsModel } from '../model/shopping-list-item-details.model';

export type ShoppingListState = {
  list: Dirtyable<Pick<WithId<ShoppingListModel>, 'id' | 'name' | 'userId' | 'sharedUserIds'> | null>;
  itemsOpen: Dirtyable<WithId<ShoppingListItemModel>[]>;
  itemsDone: Dirtyable<WithId<ShoppingListItemModel>[]>;
};

const ShoppingListContext = createContext<(ShoppingListState & { dispatch: Dispatch<ShoppingListAction> }) | undefined>(
  undefined
);
ShoppingListContext.displayName = 'CurrentUserContext';

type ShoppingListAction =
  | { type: 'SET_LIST'; list: Pick<WithId<ShoppingListModel>, 'id' | 'name' | 'userId' | 'sharedUserIds'> | null }
  | { type: 'SET_ITEMS_OPEN'; items: WithId<ShoppingListItemModel>[] }
  | { type: 'SET_ITEMS_DONE'; items: WithId<ShoppingListItemModel>[] }
  | { type: 'SET_ALL_DIRTY' }
  | { type: 'SET_ITEMS_DIRTY'; open: boolean; done: boolean };

function shoppingListReducer(state: ShoppingListState, action: ShoppingListAction) {
  switch (action.type) {
    case 'SET_LIST':
      return {
        ...state,
        list: { dirty: false, model: action.list }
      };
    case 'SET_ITEMS_OPEN':
      return {
        ...state,
        itemsOpen: { dirty: false, model: action.items }
      };
    case 'SET_ITEMS_DONE':
      return {
        ...state,
        itemsDone: { dirty: false, model: action.items }
      };
    case 'SET_ALL_DIRTY':
      return {
        ...state,
        list: { ...state.list, dirty: true },
        itemsOpen: { ...state.itemsOpen, dirty: true },
        itemsDone: { ...state.itemsDone, dirty: true }
      };
    case 'SET_ITEMS_DIRTY':
      if (!action.open && !action.done) {
        return state;
      }

      return {
        ...state,
        itemsOpen: { ...state.itemsOpen, dirty: action.open },
        itemsDone: { ...state.itemsDone, dirty: action.done }
      };
  }
}

function ShoppingListProvider({ children }: PropsWithChildren<any>) {
  const { listId = null } = useParams<'listId'>();
  const prevListId = usePrevious(listId);

  const [{ list, itemsOpen, itemsDone }, dispatch] = useReducer(shoppingListReducer, {
    list: { dirty: true, model: null },
    itemsDone: { dirty: true, model: [] },
    itemsOpen: { dirty: true, model: [] }
  });

  useLayoutEffect(() => {
    if (listId !== prevListId) {
      dispatch({ type: 'SET_ALL_DIRTY' });
    }
  }, [listId, prevListId]);

  const listDoc = useDoc<ShoppingListModel>(listId ? `lists/${listId}` : null);
  useEffect(() => {
    if (
      list.model?.id !== listDoc?.id ||
      list.model?.name !== listDoc?.name ||
      list.model?.userId !== listDoc?.userId ||
      JSON.stringify(list.model?.sharedUserIds) !== JSON.stringify(listDoc?.sharedUserIds)
    ) {
      dispatch({ type: 'SET_LIST', list: listDoc });
    }
  }, [list.model?.id, list.model?.name, list.model?.sharedUserIds, list.model?.userId, listDoc]);

  const listItemsOpen = useListItems(listId, false);
  const listItemsDone = useListItems(listId, true);

  useEffect(() => {
    dispatch({ type: 'SET_ITEMS_OPEN', items: listItemsOpen });
  }, [listItemsOpen]);
  useEffect(() => {
    dispatch({ type: 'SET_ITEMS_DONE', items: listItemsDone });
  }, [listItemsDone]);

  const value = useMemo(() => ({ list, itemsOpen, itemsDone, dispatch }), [itemsDone, itemsOpen, list]);

  return <ShoppingListContext.Provider value={value}>{children}</ShoppingListContext.Provider>;
}

export function useShoppingList() {
  const context = useContext(ShoppingListContext);
  if (!context) {
    throw new Error(`useShoppingList must be used within a ShoppingListProvider`);
  }

  const { list, itemsOpen, itemsDone } = context;

  const reducedListModel = useMemo(() => {
    if (!list.model?.id) {
      return null;
    }

    return {
      id: list.model.id,
      name: list.model.name,
      userId: list.model.userId,
      sharedUserIds: list.model.sharedUserIds
    };
  }, [list.model?.id, list.model?.name, list.model?.sharedUserIds, list.model?.userId]);

  return useMemo(
    () => ({
      list: reducedListModel,
      itemsOpen: itemsOpen.model,
      itemsDone: itemsDone.model,
      isDirty: list.dirty || itemsOpen.dirty || itemsDone.dirty
    }),
    [itemsDone.dirty, itemsDone.model, itemsOpen.dirty, itemsOpen.model, list.dirty, reducedListModel]
  );
}

export function useShoppingListFunctions() {
  const context = useContext(ShoppingListContext);
  if (!context) {
    throw new Error(`useShoppingListFunctions must be used within a ShoppingListProvider`);
  }

  const { firestore } = useFirebase();
  const { list, itemsOpen, itemsDone, dispatch } = context;

  const setItemsDirty = useCallback(
    ({ open = false, done = false }) => {
      dispatch({ type: 'SET_ITEMS_DIRTY', open, done });
    },
    [dispatch]
  );

  const createItemFunction = useCallback(
    async (inputValue: string | null | undefined, resetFunction: () => void) => {
      await addItem(inputValue, resetFunction, list.model?.id, itemsOpen.model.length, setItemsDirty, firestore);
    },
    [firestore, itemsOpen.model.length, list.model?.id, setItemsDirty]
  );

  const updateItemFunction = useCallback(
    async (details: ShoppingListItemDetailsModel, itemId: string, markedAsDone: boolean) => {
      await updateItem(details, itemId, markedAsDone, list.model?.id, setItemsDirty, firestore);
    },
    [firestore, list.model?.id, setItemsDirty]
  );

  const updateDoneStatusFunction = useCallback(
    async (itemId: string, markedAsDone: boolean) => {
      await udpateDoneStatus(
        itemId,
        markedAsDone,
        list.model?.id,
        itemsOpen.model,
        itemsDone.model,
        setItemsDirty,
        firestore
      );
    },
    [firestore, itemsDone.model, itemsOpen.model, list.model?.id, setItemsDirty]
  );

  const updateIndexFunction = useCallback(
    async (sourceIndex: number, targetIndex: number) => {
      await updateIndex(sourceIndex, targetIndex, list.model?.id, itemsOpen.model, setItemsDirty, firestore);
    },
    [firestore, itemsOpen.model, list.model?.id, setItemsDirty]
  );

  const deleteItemFunction = useCallback(
    async (itemId: string) => {
      if (!list.model?.id) {
        return;
      }

      const itemIndexesDone = itemsDone.model
        .filter(item => item.id !== itemId)
        .map(({ id, index }) => ({ id, index }));

      if (itemIndexesDone.length >= itemsDone.model.length) {
        return;
      }

      setItemsDirty({ done: true });
      const batch = writeBatch(firestore);

      batch.delete(doc(firestore, `lists/${list.model?.id}/items/${itemId}`));

      itemIndexesDone
        .sort((a, b) => a.index - b.index)
        .forEach(({ id: itemId, index: itemIndex }, arrayIndex) => {
          if (itemIndex !== arrayIndex) {
            batch.update(doc(firestore, `lists/${list.model?.id}/items/${itemId}`), { index: arrayIndex });
          }
        });

      await batch.commit();
    },
    [firestore, itemsDone.model, list.model?.id, setItemsDirty]
  );

  const deleteAllItemsFunction = useCallback(async () => {
    if (!list.model?.id) {
      return;
    }

    setItemsDirty({ done: true });
    const batch = writeBatch(firestore);
    itemsDone.model.forEach(item => batch.delete(doc(firestore, `lists/${list.model?.id}/items/${item.id}`)));
    await batch.commit();
  }, [firestore, itemsDone.model, list.model?.id, setItemsDirty]);

  const markAllItemsOpenFunction = useCallback(async () => {
    if (!list.model?.id || itemsDone.model.length < 1) {
      return;
    }

    const itemIndexesOpen = itemsOpen.model.map(({ id, index }) => ({ id, index }));

    setItemsDirty({ open: true, done: true });
    const batch = writeBatch(firestore);

    itemsDone.model.forEach(({ id: itemId }) => {
      batch.update(doc(firestore, `lists/${list.model?.id}/items/${itemId}`), { markedAsDone: false });
      itemIndexesOpen.push({ id: itemId, index: itemIndexesOpen.length * 2 });
    });

    itemIndexesOpen
      .sort((a, b) => a.index - b.index)
      .forEach(({ id: itemId, index: itemIndex }, arrayIndex) => {
        if (itemIndex !== arrayIndex) {
          batch.update(doc(firestore, `lists/${list.model?.id}/items/${itemId}`), { index: arrayIndex });
        }
      });

    await batch.commit();
  }, [firestore, itemsDone.model, itemsOpen.model, list.model?.id, setItemsDirty]);

  return useMemo(
    () => ({
      createItem: createItemFunction,
      updateItem: updateItemFunction,
      updateDoneStatus: updateDoneStatusFunction,
      markAllItemsOpen: markAllItemsOpenFunction,
      updateIndex: updateIndexFunction,
      deleteItem: deleteItemFunction,
      deleteAllItems: deleteAllItemsFunction
    }),
    [
      createItemFunction,
      deleteAllItemsFunction,
      deleteItemFunction,
      markAllItemsOpenFunction,
      updateDoneStatusFunction,
      updateIndexFunction,
      updateItemFunction
    ]
  );
}

export type SetDirtyFunction = (param: { open?: boolean; done?: boolean }) => void;

async function addItem(
  inputValue: string | null | undefined,
  resetFunction: () => void,
  listId: string | undefined,
  nextIndex: number,
  setItemsDirty: SetDirtyFunction,
  firestore: Firestore
) {
  if (!inputValue || !listId) {
    return;
  }

  const sanitizedInputValue = inputValue.trim();
  if (!sanitizedInputValue) {
    return;
  }

  const newItem = {
    index: nextIndex,
    markedAsDone: false,
    ...parseItemData(sanitizedInputValue)
  };

  setItemsDirty({ open: true });

  await addDoc(collection(firestore, `lists/${listId}/items`), newItem);

  resetFunction();
}

async function updateItem(
  details: ShoppingListItemDetailsModel,
  itemId: string,
  markedAsDone: boolean,
  listId: string | undefined,
  setItemsDirty: SetDirtyFunction,
  firestore: Firestore
) {
  if (!listId) {
    return;
  }

  setItemsDirty({ open: !markedAsDone, done: markedAsDone });

  const { note: noteToSave, quantity: quantityToSave } = details;

  const newItem = {
    ...details,
    note: noteToSave ? noteToSave : deleteField(),
    quantity: quantityToSave ? quantityToSave : deleteField()
  };

  await updateDoc(doc(firestore, `lists/${listId}/items/${itemId}`), newItem);
}

async function udpateDoneStatus(
  itemId: string,
  markedAsDone: boolean,
  listId: string | undefined,
  itemsOpen: WithId<ShoppingListItemModel>[],
  itemsDone: WithId<ShoppingListItemModel>[],
  setItemsDirty: SetDirtyFunction,
  firestore: Firestore
) {
  if (!listId) {
    return;
  }

  const item = (markedAsDone ? itemsDone : itemsOpen).find(item => item.id === itemId);
  if (!item) {
    return;
  }

  const itemIndexesOpen = itemsOpen.filter(item => item.id !== itemId).map(({ id, index }) => ({ id, index }));
  const itemIndexesDone = itemsDone.filter(item => item.id !== itemId).map(({ id, index }) => ({ id, index }));

  if (markedAsDone) {
    itemIndexesOpen.push({ id: itemId, index: itemIndexesOpen.length * 2 });
  } else {
    itemIndexesDone.push({ id: itemId, index: itemIndexesDone.length * 2 });
  }

  setItemsDirty({ open: true, done: true });

  const batch = writeBatch(firestore);

  await updateDoc(doc(firestore, `lists/${listId}/items/${itemId}`), { markedAsDone: !markedAsDone });

  itemIndexesOpen
    .sort((a, b) => a.index - b.index)
    .forEach(({ id: itemId, index: itemIndex }, arrayIndex) => {
      if (itemIndex !== arrayIndex) {
        batch.update(doc(firestore, `lists/${listId}/items/${itemId}`), { index: arrayIndex });
      }
    });

  itemIndexesDone
    .sort((a, b) => a.index - b.index)
    .forEach(({ id: itemId, index: itemIndex }, arrayIndex) => {
      if (itemIndex !== arrayIndex) {
        batch.update(doc(firestore, `lists/${listId}/items/${itemId}`), { index: arrayIndex });
      }
    });

  await batch.commit();
}

async function updateIndex(
  sourceIndex: number,
  targetIndex: number,
  listId: string | undefined,
  itemsOpen: WithId<ShoppingListItemModel>[],
  setItemsDirty: SetDirtyFunction,
  firestore: Firestore
) {
  if (!listId) {
    return;
  }

  const itemsOpenResult = Array.from(itemsOpen);
  const [removed] = itemsOpenResult.splice(sourceIndex, 1);
  itemsOpenResult.splice(targetIndex, 0, removed);

  setItemsDirty({ open: true });

  const batch = writeBatch(firestore);

  itemsOpenResult.forEach(({ id: itemId, index: itemIndex }, arrayIndex) => {
    if (itemIndex !== arrayIndex) {
      batch.update(doc(firestore, `lists/${listId}/items/${itemId}`), { index: arrayIndex });
    }
  });

  await batch.commit();
}

export default ShoppingListProvider;
