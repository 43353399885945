import { useCurrentUser } from './AuthProvider';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { PropsWithChildren } from 'react';

export type LocationState = {
  from?: Location;
};

type Props = { emailMustBeVerified?: boolean };

function Authenticated({ children, emailMustBeVerified = true }: PropsWithChildren<Props>) {
  const { user } = useCurrentUser();
  const emailVerified = user?.emailVerified || false;

  const location = useLocation();

  return user ? (
    emailVerified || !emailMustBeVerified ? (
      <>{children}</>
    ) : (
      <Navigate to={'/verify-email'} state={{ from: location }} />
    )
  ) : (
    <Navigate to={'/login'} state={{ from: location }} />
  );
}

export default Authenticated;
