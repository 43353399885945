import { Navigate } from 'react-router-dom';
import { useCurrentUser } from './AuthProvider';
import LoadingSpinner from './LoadingSpinner';

function MainListRedirect() {
  const { mainListId } = useCurrentUser();

  if (mainListId) {
    return <Navigate to={mainListId} />;
  }

  return <LoadingSpinner />;
}

export default MainListRedirect;
