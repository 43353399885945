import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, connectAuthEmulator } from 'firebase/auth';
import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Functions, getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';

type FirebaseComponents = {
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
};

const FirebaseContext = createContext<FirebaseComponents | undefined>(undefined);
FirebaseContext.displayName = 'FirebaseContext';

function FirebaseProvider({ children }: PropsWithChildren<any>) {
  const useEmulators = process.env.useEmulators === 'true';

  const value = useMemo(() => {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyDXnhQr9dNWl5Ql-JSAT3leGi1vQ2AL4og',
      authDomain: 'shopping-list-441bf.firebaseapp.com',
      databaseURL: 'https://shopping-list-441bf.firebaseio.com',
      projectId: 'shopping-list-441bf',
      storageBucket: 'shopping-list-441bf.appspot.com',
      messagingSenderId: '494985814608',
      appId: '1:494985814608:web:ca48bd63539178e2052755',
      measurementId: 'G-0H9XK3K9D3'
    };

    // Initialize Firebase
    const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
    const auth = getAuth(firebaseApp);
    const firestore = getFirestore(firebaseApp);
    const functions = getFunctions(firebaseApp);

    if (useEmulators) {
      console.log('use firebase emulators');
      connectAuthEmulator(auth, 'http://localhost:9099');
      connectFirestoreEmulator(firestore, 'localhost', 8080);
      connectFunctionsEmulator(functions, 'localhost', 5001);
    } else {
      // We only need performance measurements for production mode
      getPerformance(firebaseApp);
    }

    return { auth, firestore, functions };
  }, [useEmulators]);

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}

export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error(`useFirebase must be used within an FirebaseProvider`);
  }

  return useMemo(
    () => ({
      auth: context.auth,
      firestore: context.firestore,
      functions: context.functions
    }),
    [context.auth, context.firestore, context.functions]
  );
}

export default FirebaseProvider;
