import React, { useCallback } from 'react';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { WithId } from '../model/with-id.model';
import { ShoppingListModel } from '../model/shopping-list.model';
import useDeleteDoc from '../hooks/useDeleteDoc';
import useSetMainList from '../hooks/useSetMainList';
import { useErrorHandler } from 'react-error-boundary';
import { useCurrentUser } from './AuthProvider';
import { useUsers } from './UsersProvider';
import Gravatar from 'react-gravatar';

type Props = {
  list: WithId<ShoppingListModel>;
  isMainList: boolean;
  onClick: () => void;
};

const ListNavDropdownToggle = React.forwardRef<HTMLElement, React.ComponentPropsWithoutRef<'i'>>(
  ({ children, onClick }, ref) => (
    <i
      ref={ref}
      className="bi bi-three-dots-vertical d-flex m-auto ms-2 cursor-pointer"
      role="img"
      aria-label="toggle list nav dropdown"
      onClick={e => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    />
  )
);

function ListNavItem({ list: { id, name, numberOfItems, userId }, isMainList, onClick }: Props) {
  const pathname = `/lists/${id}`;
  const resolvedPath = useResolvedPath(pathname);
  const match = useMatch({ path: resolvedPath.pathname, end: false });
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { user } = useCurrentUser();
  const users = useUsers();

  const renamePath = `${pathname}/rename`;
  const onRename = useCallback(() => {
    navigate(renamePath);
    onClick();
  }, [navigate, onClick, renamePath]);

  const sharePath = `${pathname}/share`;
  const onShare = useCallback(() => {
    navigate(sharePath);
    onClick();
  }, [navigate, onClick, sharePath]);

  const deleteList = useDeleteDoc(`lists/${id}`);
  const onDeleteList = useCallback(async () => {
    await deleteList();

    if (match) {
      navigate('/lists');
    }
  }, [deleteList, match, navigate]);

  const setMainList = useSetMainList();
  const onSetMainList = useCallback(() => {
    setMainList(id).catch(handleError);
  }, [handleError, id, setMainList]);

  const userEmail = users.find(u => u.uid === userId)?.email;

  return (
    <Dropdown>
      <NavItem className="d-flex flex-row">
        <NavLink
          as={Link}
          className="d-flex flex-grow-1"
          to={pathname}
          eventKey={pathname}
          active={!!match}
          onClick={onClick}
        >
          <span className="flex-grow-1">
            {name} ({numberOfItems})
          </span>
          {userId !== user?.uid && userEmail ? (
            <Gravatar email={userEmail} rating="pg" default="identicon" size={25} className="rounded-circle" />
          ) : null}
        </NavLink>

        <Dropdown.Toggle as={ListNavDropdownToggle} id="dropdown-custom-components" />
        <Dropdown.Menu>
          <Dropdown.Item eventKey={renamePath} active={false} onClick={onRename}>
            umbenennen
            <i className="bi bi-pencil float-end" role="img" aria-label="rename list" />
          </Dropdown.Item>
          <Dropdown.Item eventKey={sharePath} active={false} onClick={onShare}>
            teilen
            <i className="bi bi-share float-end" role="img" aria-label="share list" />
          </Dropdown.Item>
          {!isMainList ? (
            <>
              <Dropdown.Item eventKey={`${pathname}/set-default`} active={false} onClick={onSetMainList}>
                als Hauptliste festlegen
                <i className="bi bi-house float-end" role="img" aria-label="set list as main list" />
              </Dropdown.Item>
              <Dropdown.Item eventKey={`${pathname}/delete`} active={false} onClick={onDeleteList}>
                löschen
                <i className="bi bi-trash float-end" role="img" aria-label="delete list" />
              </Dropdown.Item>
            </>
          ) : null}
        </Dropdown.Menu>
      </NavItem>
    </Dropdown>
  );
}

export default ListNavItem;
