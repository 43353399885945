import styles from './Sidebar.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import useUserLists from '../hooks/useUserLists';
import { signOut } from 'firebase/auth';
import { useFirebase } from './FirebaseProvider';
import ListNavItem from './ListNavItem';
import NavLink from 'react-bootstrap/NavLink';
import React, { useCallback } from 'react';
import NavItem from 'react-bootstrap/NavItem';
import { useCurrentUser } from './AuthProvider';
import Gravatar from 'react-gravatar';

type Props = { visible: boolean; hideSidebar: () => void };

function Sidebar({ visible, hideSidebar }: Props) {
  const { mainList, userLists, sharedLists } = useUserLists();

  const allLists = mainList ? [mainList, ...userLists, ...sharedLists] : [...userLists, ...sharedLists];

  const matchCreateList = useMatch({ path: useResolvedPath('/lists/new').pathname, end: false });

  return (
    <>
      <div
        className={`d-flex flex-column flex-shrink-0 p-3 text-white bg-dark ${styles.sidebar} ${
          !visible ? 'visually-hidden' : ''
        }`}
      >
        <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <span className="fs-4">Listen</span>
        </Link>
        <hr />
        <Nav variant="pills" className="flex-column mb-auto text-white bg-dark">
          {allLists.map(list => (
            <ListNavItem key={list.id} list={list} onClick={hideSidebar} isMainList={list.id === mainList?.id} />
          ))}
          <hr />
          <NavItem className="d-flex flex-row">
            <NavLink
              as={Link}
              className="d-flex flex-grow-1"
              to="/lists/new"
              eventKey="/lists/new"
              active={!!matchCreateList}
              onClick={hideSidebar}
            >
              Neue Liste erstellen
            </NavLink>
          </NavItem>
        </Nav>
        <hr />
        <UserDropdown hideSidebar={hideSidebar} />
      </div>
      <div className={`backdrop ${styles.backdrop} ${!visible ? 'visually-hidden' : ''}`} onClick={hideSidebar}></div>
    </>
  );
}

function UserDropdown({ hideSidebar }: { hideSidebar: () => void }) {
  const { user } = useCurrentUser();
  const { auth } = useFirebase();
  const navigate = useNavigate();

  const onSignOut = useCallback(
    async (event: any) => {
      event.preventDefault();
      await signOut(auth);
      navigate('/');
    },
    [auth, navigate]
  );

  return (
    <Dropdown className="bg-dark">
      <Dropdown.Toggle id="dropdownUser1" variant="secondary">
        <Gravatar email={user?.email!} rating="pg" default="identicon" size={32} className="rounded-circle me-2" />
        <strong>Einstellungen</strong>
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item as={Link} to="/user" onClick={hideSidebar}>
          Profil
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={onSignOut} href="#">
          Abmelden
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Sidebar;
